@charset "utf-8";
@import "_format";
@import "_variables";
/*-------------------------------
  flex
-------------------------------*/
@mixin fx {
  display: flex;
}
@mixin fx_ac {
  display: flex;
  align-items: center;
}
@mixin fx_ac_jc {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin fx_w_a {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
}
@mixin fx_w_js {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
@mixin fx_js {
  display: flex;
  justify-content: space-between;
}
@mixin fx_c_js {
	display: flex;
	align-items: center;
	justify-content: space-between;
}
@mixin fx_jc_dc {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@mixin fx_jc {
  display: flex;
  justify-content: center;
}
@mixin fx_dc {
  display: flex;
  flex-direction: column;
}
/*-------------------------------
  font
-------------------------------*/
@mixin fz($size, $base: 16) {
  font-size: $size + px;
  font-size: ($size / $base) + rem;
}
@mixin txt_in {
  text-indent: -1em;
  margin-left: 1em;
}
@mixin font_utsukushi {
  font-family: $font_utsukushi;
  @include fz(44);
  color: $clr_pnk;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  @include tab {
    @include fz(35);
  }
  @include sp {
    @include fz(25);
  }
}
/*-------------------------------
  width
-------------------------------*/
@mixin full_width(){
  margin-left: calc((100vw - 100%) / -2);
  margin-right: calc((100vw - 100%) / -2);
  max-width: auto;
  max-width: initial;
  width: auto !important;
}
/*-------------------------------
 break-point
-------------------------------*/
$breakpoint: 667px;
$pc-width: 1024px;
$tab-width: 900px;

@mixin pc {
	@media screen and (max-width: 1350 + px) {
		@content;
	}
}
@mixin pc_s {
	@media screen and (max-width: 1000 + px) {
		@content;
	}
}
@mixin pc_tab {
	@media screen and (min-width: $tab-width)and(max-width: 1000 + px) {
		@content;
	}
}
@mixin tab {
  @media screen and (min-width: $breakpoint) and (max-width: $tab-width)  {
    @content;
  }
}
@mixin tab_s {
	@media screen and (max-width: $tab-width) {
		@content;
	}
}
@mixin sp {
	@media screen and (max-width: $breakpoint) {
		@content;
	}
}
@mixin sp_s {
	@media screen and (max-width: 320 + px) {
		@content;
	}
}
/*-------------------------------
 browser
-------------------------------*/
@mixin ie {
    @media all and (-ms-high-contrast: none), print {
        @content;
    }
}
@mixin rt {
    @media only screen and (-webkit-min-device-pixel-ratio: 2) {
        @content;
    }
}
/*------------------------------
  position
-------------------------------*/
@mixin ps_50_50 {
 position: absolute; 
  top: 50%; 
  left: 50%; 
  -ms-transform: translate(-50%,-50%); 
  -webkit-transform: translate(-50%,-50%); 
  transform: translate(-50%,-50%); 
} 
  
  
  
  
