@charset "utf-8";
@import "_mixin";
@import "_variables";
/*-------------------------------
contents
-------------------------------*/

/*--------------------------------------
  header
---------------------------------------*/
.header_top {
  max-width: 1300px;
  height: 95px;
  margin: 0 auto 50px auto;
  position: relative;
  @include fx_c_js;
  @include pc {
    width: 90%;
  }
  @include tab_s {
    margin: 0 auto;
  }
  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 15px;
    background: url("../../assets/img/h_line.png") center 50% /contain no-repeat;
    position: absolute;
    bottom: -15px;
    left: 0;
    @include sp {
      bottom: 0;
    }
  }
  .h_logo {
    @include sp {
      width: 30%;
    }
  }
  .h_link {
    @include sp {
      width: 40%;
      margin-right: 3em;
    }
  }
}
.kv_area {
  width: 100%;
  height: 695px;
  background: url(../../assets/img/kv_img_pc.png) center 50% /contain no-repeat;
  @include pc {
    width: 90%;
    height: auto;
    padding-top: 50%;
    margin: 0 auto;
  }
  @include tab {
    height: 450px;
  }
  @include sp {
    height: 450px;
    background: url(../../assets/img/kv_img_sp.png) center 50% /contain no-repeat;
  }
}
.header_contents {
  width: 100%;
  margin: 0 auto 90px auto;
  position: relative;
  @include sp {
    margin-bottom: 2em;
  }
}
.header_inner {
  max-width: 670px;
  margin: 0 auto;
	position: relative;
}
.header_nav_contents {
  @include sp {
		max-width: 100%;
		height: 100vh;
		background: #e9ddcd;
		text-align: center;
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 99;
    padding-top: 0;
  }
  ul {
    @include fx_c_js;
		@include sp {
      width: 100%;
		  flex-direction: column;
		  align-items: stretch;
      padding-top: 7em;
      li {
        margin-bottom: 2em;
      }
    }	
		.h_nav {
		  display: block;
      font-family: $font_utsukushi;
      @include fz(20);
      font-weight: 600;
		  position: relative;
      &:after {
        content: "";
        display: block;
        background: #eacebe;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        position: absolute;
        bottom: -50%;
        left: 50%;
        transform: translate(-50%,-50%); 
      }
       &:hover {
         position: relative;
         opacity: 1;
         &:after {
           background:#e8928b;
         }
       }
      sup {
        @include fz(10);
      }
		 }
	}
}

.nav_txt {
  @include font_utsukushi;
  margin-bottom: 20px;
}
.drawer_nav_wrapper,
.menu_trigger {
    display: none;
}
@include sp {
	.drawer_nav_wrapper {
     @include fx_w_js;
     justify-content: flex-end;
     padding: 0 20px;
     position: fixed;
     top: 32px;
     right: 0;
     z-index: 999;
     box-sizing: border-box;
    }

	.menu_trigger {
	  display: inline-block;
	  position: relative;
	  width: 36px;
	  height: 27px;
	  vertical-align: middle;
	  cursor: pointer;
		}
	.menu_trigger span {
    display: inline-block;
	  box-sizing: border-box;
	  position: absolute;
	  left: 0;
	  width: 2rem;
    height: 3px;
	  background: $clr_pnk;
	  transition: all .5s .2s;
  }
	.menu_trigger.active span {
	  background: $clr_pnk;
		}
	.menu_trigger span:nth-of-type(1) {
	  top: 0;
		}
	.menu_trigger.active span:nth-of-type(1) {
	  transform: translateY(12px) rotate(-45deg);
		}
	.menu_trigger span:nth-of-type(2) {
	  top: 12px;
		}
	.menu_trigger.active span:nth-of-type(2) {
	  opacity: 0;
		}
	.menu_trigger span:nth-of-type(3) {
	  bottom: 0;
		}
	.menu_trigger.active span:nth-of-type(3) {
	  transform: translateY(-12px) rotate(45deg);
		}
  
  .active {
    overflow: hidden;

    .header_nav_contents {
      visibility: visible;
      opacity: 1;

      li {
        opacity: 1;
        transform: translateX(0);
        transition:  transform 1s ease, opacity .9s ease;
        text-align: center;
      }
    }
  }
  .header_nav_contents {
    li {
      opacity: 0;
      transform: translateX(200px);
      transition:  transform .6s ease, opacity .2s ease;

      &:nth-child(2) {
        transition-delay: .15s;
      }
      &:nth-child(3) {
        transition-delay: .3s;
      }
      &:nth-child(4) {
        transition-delay: .45s;
      }
      &:nth-child(5) {
        transition-delay: .65s;
      }
      &:nth-child(6) {
        transition-delay: .75s;
      }
    }
  }
}
/*-------------------------------
common 
-------------------------------*/
.sec_contents {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.sec_inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 75px 0;
  text-align: center;
  @include pc_s {
    max-width: 85%;
  }
  @include sp {
    padding: 2em 0;
  }
}
.sec_ttl {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
  &:before {
    content: "";
    display: inline-block;
    background: url(../../assets/img/concept/concept_ttl_img.png) center 50% /contain no-repeat;
    width: 100%;
    height: 63px;
    margin-bottom: 30px;
    @include sp {
      height: 50px;
      margin-bottom: .5em;
    }
  }
  &_item {
    @include fz(16); 
    line-height: 1.5em;
    margin-bottom: 10px;
  }
  &_s {
    @include font_utsukushi;
  }
  span {
    display: block;
  }
}
.sec_lead {
  @include font_utsukushi;
  @include fz(31); 
}
.sec_txt {
  @include fz(18);
  font-weight: 500;
  @include sp {
    @include fz(16);
  }
}
.sec_cat_sd {
  @include font_utsukushi;
  @include fz(37);
  color: $clr_base;
  margin-bottom: 30px;
  position: relative;
  &:before,
  &:after {
    content: "";
    display: inline-block;
    width: 2px;
    height: 40px;
    background: $clr_base;
    transform: rotate(-45deg);
    position: absolute;
    bottom: 8%;
    @include sp {
      height: 25px;
    }
  }
  &:before {
    left: 40%;
    @include sp {
      left: 30%;
    }
  }
  &:after {
    transform: rotate(-140deg);
    right: 40%;
    @include sp {
      right: 30%;
    }
  }
}
.anchor {
  @include sp {
    padding-top: 70px;
    margin-top: -70px;
    }
}
.btn {
  display: block;
  width: 430px;
  height: 70px;
  margin: 80px auto;
  padding-top: 3px;
  border-radius: 2em;
  @include fz(26);
  color: #fff;
  text-align: center;
  background: $clr_org;
  border: 2px solid $clr_org;
  @include fx_jc_dc;
  position: relative;
  @include sp {
    width: 90%;
    height: auto;
    margin: 2em auto;
    padding: .5em 0;
    @include fz(22);
    line-height: 1.2em;
  }
  &:after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 50%;
    right: 7%;
    margin-top: -6px;
    width: 12px;
    height: 12px;
    border-right: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-45deg);
  }
  &:hover {
    color: $clr_org;
    background: #fff;
    &:after {
     border-right: 2px solid $clr_org;
     border-bottom: 2px solid $clr_org; 
    }
  }
}
/* fadeUp */
.fadeUp {
animation-name:fadeUpAnime;
animation-duration: 1s;
animation-fill-mode:forwards;
opacity:0;
}

@keyframes fadeUpAnime{
  from {
    opacity: 0;
	transform: translateY(100px);
  }

  to {
    opacity: 1;
	transform: translateY(0);
  }
}
.fadedown {
animation-name:fadedown;
animation-duration: 1s;
animation-fill-mode:forwards;
opacity:0;
}
/*fadedown*/
@keyframes fadedown {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(50px);
  }
}
.fadeUpTrigger{
    opacity: 0;
}


#contents {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}
sup {
  @include fz(18);
  vertical-align: top;
  position: relative;
  top: -0.5em;
  }
/*-------------------------------
戸建大家さんって？
-------------------------------*/
#concept {
  .sec_inner {
    max-width: 1020px;
    @include tab_s {
      width: 90%;
    }
    .tab_sp {
      @include sp {
        width: 85%;
        margin: 0 auto;
      }
    }
    .sec_ttl_s {
      sup {
       @include fz(16);
        top: -0.9em;
      }
    }
    .sec_txt {
      sup {
        top: -0.2em;
      }
    }
  }
  .sec_concept {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
    @include tab_s {
      flex-direction: column;
    }
    @include sp {
      width: 90%;
      margin: 0 auto 3em auto;
    }
    &_lt {
      width: 390px;
      height: 390px;
      border-radius: 50%;
      background: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: -5.5em;
      padding-top: 3em;
      @include tab_s {
        margin: 4em auto 8em;
      }
      @include sp {
      }
      &_cat {
        margin-bottom: 30px;
      }
      &_mt {
        width: 188px;
        height: 89px;
        background: url(../../assets/img/concept/concept_item_img_m.png) center 50% /contain no-repeat;
        position: relative;
        z-index: 2;
        &:hover {
          cursor: pointer;
          background: url(../../assets/img/concept/concept_item_img_m_on.png) center 50% /contain no-repeat;
        }
        &:before {
          content: "";
          display: inline-block;
          background: url(../../assets/img/concept/concept_item_img_t.png) center 50% /contain no-repeat;
          width: 210px;
          height: 58px;
          position: absolute;
          top: -65%;
          left: -5%;
          z-index: -1;
        }
        &:after {
          content: "";
          display: inline-block;
          background: url(../../assets/img/concept/concept_item_txt_pc.png) center 50% /contain no-repeat;
          width: 237px;
          height: 167px;
          position: absolute;
          top: -130%;
          right: -125%;
          @include tab_s {
            width: 300px;
            height: 290px;
            background: url(../../assets/img/concept/concept_item_txt_sp.png) center 50% /contain no-repeat;
            top: -270%;
            right: -50%;
          }
        }
      }
      &_bt {
        width: 188px;
        height: 85px;
        background: url(../../assets/img/concept/concept_item_img_b.png) center 50% /contain no-repeat;
        position: relative;
        &:hover {
          cursor: pointer;
          background: url(../../assets/img/concept/concept_item_img_b_on.png) center 50% /contain no-repeat;
        }
        &:after {
          content: "";
          display: inline-block;
          background: url(../../assets/img/concept/concept_item_txt02_pc.png) center 50% /contain no-repeat;
          width: 237px;
          height: 144px;
          position: absolute;
          top: 45%;
          right: -125%;
          @include tab_s {
            width: 300px;
            height: 190px;
            background: url(../../assets/img/concept/concept_item_txt02_sp.png) right /contain no-repeat;
            right: -40%;
          }
        }
      }
    }
    &_rt {
      &_tp {
        margin-bottom: 55px;
        position: relative;
        z-index: 2;
        &:before {
          content: "";
          display: inline-block;
          width: 483px;
          height: 335px;
          background: url(../../assets/img/concept/concept_item_rt_bg.png) center 50% /contain no-repeat;
          position: absolute;
          top: -6.5%;
          right: -5%;
          z-index: -2;
          @include sp {
            width: 305px;
            height: 215px;
            top: -10%;
          }
        }
        &_inner {
          position: relative;
          @include sp {
            width: 270px;
          }
          &:before,
          &:after {
            content: "";
            display: inline-block;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: #fff;
            position: absolute;
            bottom: -7px;
            z-index: 1;
            @include sp {
              width: 10px;
              height: 10px;
              bottom: -3px;
            }
          }
          &:before {
            left: -7px;
            @include sp {
              left: -3px;
            }
          }
          &:after {
            right: -7px;
            @include sp {
              right: -3px;
            }
          }
        }
        &_img {
          width: 440px;
          height: 248px;
          overflow: hidden;
          position: relative;
          @include sp {
            width: 100%;
            height: 150px;
          }
          img {
            transition:1s all;
            &:hover {
              cursor: pointer;
              transform: scale(1.2);
              transition:1s all;
            }
          }
        }
        &:nth-of-type(2) {
          margin-bottom: 0;
        }
      }
    }
  }
  .sec_img {
    margin-bottom: 35px;
  }
  .sec_txt_img {
    margin-bottom: 25px;
  }
  .sec_item {
    width: 97%;
    margin: 0 auto;
    background: #fff;
    text-align: center;
    @include sp {
      width: 90%;
    }
    &_ttl_area {
      width: 100%;
      height: auto;
      padding-top: calc(114 / 921 * 100%);
      background: url(../../assets/img/concept/concept_ttl_img_bg.png) center 50% /contain no-repeat;
      position: relative;
      margin-bottom: 55px;
      @include sp {
        background: url(../../assets/img/concept/concept_ttl_img_bg_sp.png) center 50% /contain no-repeat;
        padding-top: 27%;
        margin-bottom: 2em;
      }
    }
    &_ttl {
     @include fz(36);
     color: #fff;
     font-weight: 600;  
     @include ps_50_50;
     top: 35%;
      @include tab_s {
        @include fz(30);
      }
      @include sp {
        @include fz(20);
        line-height: 1.2em;
      }
      &_s {
        margin-bottom: 20px;
      }
    }
    &_js {
      display: flex;
      align-items: center;
      justify-content: space-between;
      @include sp {
        flex-direction: column;
      }
    }
    &_img {
      margin-bottom: 40px;
    }
    .sec_txt {
      margin-bottom: 25px;
    }
    .sec_cat {
      margin-bottom: 10px;
    }
    .arrow {
      margin-top: 6em;
      @include sp {
        transform:rotate(90deg);
        margin-top: -2em;
      }
    }
  }
  .sec_cat_sd {
    margin-bottom: 20px;
  }
  .ac_cc img {
    transform: scale(1.2);
    transition:1s all;
  }
  .ac_cc02 {
    background: url(../../assets/img/concept/concept_item_img_m_on.png) center 50% /contain no-repeat!important;
    transition:1s all;
  }
  .ac_cc03 {
    background: url(../../assets/img/concept/concept_item_img_b_on.png) center 50% /contain no-repeat!important;
    transition:1s all;
  }
}
/*-------------------------------
実例
-------------------------------*/
#case {
  background: #fff;
  .sec_inner {
    padding-bottom: 100px;
  }
  .sec_ttl {
    margin-bottom: 90px;
    @include sp {
      margin-bottom: 2em; 
    }
    &:before {
      height: 67px;
      background: url(../../assets/img/case/case_ttl_img.png) center 50% /contain no-repeat;
      @include sp {
        height: 55px;
      }
    }
    &_s {
      color: $clr_org;
    }
  }
  .case_item {
    margin-bottom: 150px;
    @include sp {
      margin-bottom: 4em;
    }
    .sec_cat {
      width: 80%;
      @include fz(36);
      font-weight: 500;
      color: #fff;
      @include tab {
        width: 85%;
        @include fz(30);
        line-height: 1.1em;
      }
      @include sp {
        width: 75%;
        @include fz(22);
        line-height: 1.1em;
      }
      &_area {
        max-width: 830px;
        margin: 0 auto 60px auto;
        
        @include sp {
          margin-bottom: 2em; 
        }
      }
      &_inner {
        width: 95%;
        height: 75px;
        line-height: 75px;
        margin: 0 0 0 auto;
        background: #c0716d;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        @include sp {
          width: 85%;
        }
        &:before,
        &:after {
          content: '';
          position: absolute;
          width: 0px;
          height: 0px;
          z-index: 1;
          top: 0;
          border-width: 37px 20px 37px 0;
          border-style: solid;
        }
        &:before {
          right: 1px;
          border-color: transparent #000 transparent transparent;
          
        }
        &:after {
          right: 0;
          border-width: 37px 20px 37px 0;
          border-color: transparent #fff transparent transparent;
        }
      }
      &_num {
        display: block;
        width: 125px;
        height: 125px;
        border-radius: 50%;
        border: 1px solid #000;
        background: #fff;
        margin: 0 0 0 -5em;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @include tab {
          width: 105px;
          height: 105px;
        }
        @include sp {
          width: 80px;
          height: 80px;
        }
        &:after{
          content: "";
          position: absolute;
          width: 115px;
          height: 115px;
          border-radius: 50%;
          border: 1px solid #000;
          @include tab {
            width: 95px;
            height: 95px;
          }
          @include sp {
            width: 70px;
            height: 70px;
          }
        }
        p {
          font-family: $font_utsukushi;
          @include fz(18);
          line-height: 1.6em;
          @include sp {
            @include fz(14);
          }
        }
        span {
          display: block;
          @include fz(35);
          @include sp {
            @include fz(22);
          }
        }
      }
    }
    &_cat {
      width: 200px;
      height: 35px;
      line-height: 35px;
      margin: 0 auto 15px;
      border-radius: 1em;
      border: 1px solid $clr_base;
      @include fz(16);
      background: #ffe7ab;
    }
    &_txt {
      line-height: 2.5em;
      margin-bottom: 30px;
      @include sp {
        line-height: 2em;
        text-align: left;
      }
    }
    &_txt_s {
      @include fz(40);
      color: $clr_pnk;
      vertical-align: middle;
      padding-left: .1em;
      @include sp {
        @include fz(30);
      }
    }
    &_des {
      font-family: $font_utsukushi;
      @include fz(28);
      font-weight: bold;
      letter-spacing: -.07em;
      @include sp {
        @include fz(25);
        text-align: left;
        margin-bottom: .5em;
      }
      &_s {
        font-family: $font_yumin;
        @include fz(30);
        color: $clr_pnk;
        @include sp {
          @include fz(26);
        }
      }
      &_num {
        font-family: $font_yumin;
        @include fz(48);
        color: $clr_pnk;
        font-weight: 500;
        @include sp {
          @include fz(30);
        }
      }
    }
    &_note {
      @include fz(14);
    }
    &:nth-last-of-type(1) {
      margin-bottom: 0;
      .sec_cat {
        &:before {
          background: url(../../assets/img/case/case02.png) center 50% /contain no-repeat;
        }
      }
    }
  }
  .mv_img {
    @include tab_s {
      margin-bottom: 3em;
    }
  }
  .tm_img {
    margin: 0 0 50px -5px;
    .slick-slide {
      padding: 10px 5px 0;
      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }
  }
   @include tab_s {
    .slick-arrow {
      position: absolute;
      top: 45%;
      bottom: 0;
      margin: auto;

      &:hover {
        cursor: pointer;
        opacity: .8;
      }
    }
    .prev {
      left: 5%;
      z-index: 5;
        &:after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(45deg); 
        }
    }
    .next {
      right: 5%;

      &:after {
        content: "";
        display: inline-block;
        width: 20px;
        height: 20px;
        border-left: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotate(-135deg); 
      }
    }
  }
}
/*-------------------------------
8つの特徴
-------------------------------*/
#merit {
  .sec_ttl {
    margin-bottom: 60px;
    @include tab_s {
     margin-bottom: 30px;
    }
    &:before {
      height: 83px;
      background: url(../../assets/img/merit/merit_ttl_img.png) center 50% /contain no-repeat;
      @include tab_s {
       height: 55px;
      }
    }
    &_s {
      color: $clr_kpnk;
    }
  }
  .merit_item {
    height: 100px;
    border-radius: 3em;
    box-shadow: 0px 12px 0px -5px $clr_org;
    background: #fff;
    margin-bottom: 28px;
    @include fx_ac_jc;
    position: relative;
    @include sp {
      height: 120px;
      border-radius: 4em;
      box-shadow: 0px 10px 0px -5px $clr_org;
    }
    &:before {
      content: "";
      display: inline-block;
      background: url(../../assets/img/merit/num_01.png) left /contain no-repeat;
      width: 5%;
      height: 33px;
      position: absolute;
      left: 70px;
      top: 50%;
      margin-top: -18px;
      @include tab {
       left: 50px;
       height: 25px;
      }
      @include sp {
       top: 15%;
       left: 13%;
       width: 6%;
       margin-top: 0;
      }
    }
    &:hover {
      cursor: pointer;
      background: #ffe7ab;
    }
    &:nth-of-type(even) {
      box-shadow: 0px 12px 0px -5px $clr_bpnk;
      @include sp {
        box-shadow: 0px 10px 0px -5px $clr_bpnk;
      }
    }
    &:nth-of-type(2) {
      &:before {
      background: url(../../assets/img/merit/num_02.png) left /contain no-repeat;
      }
    }
    &:nth-of-type(3) {
      &:before {
      background: url(../../assets/img/merit/num_03.png) left /contain no-repeat;
      }
    }
    &:nth-of-type(4) {
      &:before {
      background: url(../../assets/img/merit/num_04.png) left /contain no-repeat;
      }
    }
    &:nth-of-type(5) {
      &:before {
      background: url(../../assets/img/merit/num_05.png) left /contain no-repeat;
      }
    }
    &:nth-of-type(6) {
      &:before {
      background: url(../../assets/img/merit/num_06.png) left /contain no-repeat;
      }
    }
    &:nth-of-type(7) {
      &:before {
      background: url(../../assets/img/merit/num_07.png) left /contain no-repeat;
      }
    }
    &:nth-of-type(8) {
      &:before {
      background: url(../../assets/img/merit/num_08.png) left /contain no-repeat;
      }
    }
    &_txt {
      @include font_utsukushi;
      @include fz(30);
      color: $clr_kpnk;
      @include tab {
        @include fz(25);
      }
      @include sp {
        @include fz(20);
      }
    }
  }
  .btn {
    margin: 80px auto 25px auto;
  }
}
/*-------------------------------
開発ストーリー
-------------------------------*/
#story {
  background:#f2e7de;
  .sec_ttl {
    &:before {
      height: 83px;
      background: url(../../assets/img/story/story_ttl_img.png) center 50% /contain no-repeat;
      @include sp {
        height: 55px;
      }
    }
    &_s {
      color: $clr_org;
    }
  }
  .sec_txt {
    margin-bottom: 30px;
    @include sp {
      text-align: left;
    }
  }
  .sec_lead {
    @include fz(30);
    color: $clr_org;
    @include sp {
      @include fz(23);
      text-indent: -1em;
      margin-left: 1em;
      
    }
  }
  .sec_cat {
    @include fz(20);
    margin-bottom: 70px;
    &_s {
      @include fz(18);
      background: #fff;
      color: $clr_org;
      font-weight: bold;
      border-radius: 1em;
      padding: 5px 30px;
      margin-right: 5px;
      @include sp {
        display: inline-block;
        border-radius: 3em;
        margin: 0 auto .8em auto;
      }
    }
  }
  .sec_cat_b {
    @include fz(20);
    margin-bottom: 20px;
  }
  .story_item {
    max-width: 800px;
    margin: 0 auto 100px auto;
    background: #fff;
    padding: 55px 0 25px 0;
    @include sp {
      padding: 2em 1em;
      margin: 0 auto 2em auto;
    }
    &_inner {
      margin-bottom: 25px;
    }
    &_ttl {
      margin-bottom: 20px;
      @include sp {
        text-align: left;
        margin-bottom: .2em;
      }
      .num {
        vertical-align: text-bottom;
        margin-right: .2em;
        @include sp {
          text-align: left;
          margin-right: .2em;
          img {
            width: 7%;
          }
        }
      }
    }
    &_txt {
      @include fz(20);
      @include sp {
        @include fz(16);
        text-align: left;
      }
      &_sp {
        @include sp {
          text-align: center;
        }
        sup {
          top: -.3em;
        }
      }
    }
    &_cat_b {
      @include font_utsukushi;
      @include fz(40);
      color: $clr_org;
    }
    &_ball_area {
      position: relative;
      &:before {
        content: "";
        display: inline-block;
        background: url(../../assets/img/story/story_des.png) right 50% /contain no-repeat;
        width: 100%;
        height: 304px;
        position: absolute;
        top: -25%;
        right: -2%;
        @include tab {
          height: 250px;
          top: 0;
        }
        @include sp {
          height: 180px;
          top: 7em;
        }
      }
    }
    &_ball {
      width: 550px;
      height: 100px;
      margin: 0 auto 115px auto;
      @include font_utsukushi;
      @include fz(20);
      color: $clr_base;
      border-radius: 3em;
      box-shadow: 5px 7px 0px 0px $clr_org;
      background: #fff;
      @include fx_ac_jc;
      position: relative;
      @include tab {
        width: 75%;
        margin: 0 auto 6em 0;
        @include fz(20);
      }
      @include sp {
        width: 100%;
        height: 80px;
        margin: 0 auto 10em 0;
        @include fz(18);
      }
      &:after {
        content: "";
        display: inline-block;
        background: url(../../assets/img/story/story_des_icon.png) right 50% /contain no-repeat;
        width: 52px;
        height: 27px;
        position: absolute;
        bottom: -26%;
        right: 8%;
        @include sp {
          bottom: -33%;
          right: 10%;
        }
      }
    }
    &_list {
      padding: 40px 0 70px 0;
      margin: 0 auto;
      background: #fff;
      box-shadow: 0 0 9px rgba(0,0,0,.3);
      position: relative;
      z-index: 1;
      @include tab_s {
        padding: 3em 1em;
      }
      &_ttl {
        position: absolute;
        top: -60px;
        left: 50px;
        @include sp {
          width: 40%;
          top: -2.7em;
          left: .5em;
        }
      }
      &_img {
        @include sp {
          width: 90%;
          margin: 0 auto;
        }
      }
      &_area {
        .story_item_cat {
           @include font_utsukushi;
           @include fz(24);
           color: $clr_base;
           margin-bottom: 70px;
            @include sp {
              @include fz(22);
              margin-bottom: 1.5em;
            }
          sup {
            @include fz(13);
          }
        }
        &:nth-last-of-type(1) {
          .story_item {
            &_ball_area {
              &:before {
                background: url(../../assets/img/story/story_des02.png) right 50% /contain no-repeat;
                top: -70%;
                right: -9%;
                height: 411px;
                @include tab {
                  height: 350px;
                  top: 105%;
                }
                @include sp {
                  height: 250px;
                  top: 105%;
                }
              }
            }
            &_ball {
              width: 80%;
              margin: 0 auto 115px -30px;
              text-align: justify;
              padding: 0 3.5em;
              @include tab {
                width: 100%;
                height: 110px;
                margin: 0 auto 10em auto;
                padding: 0 2em;
                &:after {
                  bottom: -25%;
                }
              }
              @include sp {
                width: 100%;
                height: 200px;
                margin: 0 auto 9em auto;
                padding: 0 1.5em;
                &:after {
                  bottom: -13%;
                  right: 15%;
                }
              }
            }
            &_cat {
             margin-bottom: 25px; 
            }
          }
        }
      }
      &_btm {
        max-width: 760px;
        margin: 30px auto 0 auto;
        padding: 30px;
        background: #79bac2;
        border-radius: .5em;
        @include sp {
          padding: 1em;
        }
        &_cat {
          @include font_utsukushi;
          @include fz(28);
          color: #fff;
          margin-bottom: 15px;
          @include tab {
            @include fz(25);
          }
          @include sp {
            @include fz(20);
          }
        }
        &_txt {
          @include font_utsukushi;
          @include fz(30);
          color: #79bac2;
          font-weight: bold;
          background: #fff;
          padding: 20px 0;
          @include tab {
            @include fz(27);
          }
          @include sp {
            @include fz(22);
            padding: .5em 0;
          }
        }
      }
    }
  }
  .arrow {
    display: block;
    margin: -80px auto 70px auto;
    @include sp {
      width: 45%;
      margin: -70px auto 70px auto;
    }
  }
  .sec_cat_sd {
    margin-bottom: 35px;
  }
  .btn {
    margin: 70px auto 0 auto;
  }
}
/*-------------------------------
お部屋を貸さなくても
使い方は自由自在！
-------------------------------*/
#ex {
  background: $clr_spnk;
  .sec_inner {
    padding: 75px 0 55px 0;
  }
  .ex_item {
    &_ttl {
      @include font_utsukushi;
      @include fz(30);
      letter-spacing: .08em;
      margin-bottom: 30px;

      span {
        display: block;
        margin-bottom: 25px;
      }
    }
    &_cat {
      max-width: 780px;
      height: 55px;
      margin: 0 auto 50px auto;
      border-radius: 3em;
      @include fz(20);
      background: #fff;
      @include fx_jc_dc;
      @include tab {
        line-height: 1.3em;
        height: auto;
        padding: .5em;
      }
      @include sp {
        height: auto;
        @include fz(16);
        line-height: 1.4em;
        padding: 1em 1.5em;
        margin-bottom: 1.5em;
        text-align: justify;
      }
    }
    &_list {
     @include fx_w_js;
      li {
        margin-bottom: 20px;
        position: relative;
        @include sp {
          width: 49%;
          margin-bottom: 1em;
        }
        &:nth-child(3n+2) {
          p {
            background: #e19fb2;
          }
        }
        &:nth-child(3n) {
          p {
            background: #e68b73;
          }
        }
        &:hover {
          transition: 1s all;
          .ex_item_list_txt {
            display: block;
            transition: 1s all;
            @include tab_s {
              display: none;
            }
          }
        }
      }
      &_txt {
        display: none;
        width: 195px;
        height: 95px;
        border-radius: 50% / 0 0 100% 100%;
        background: #c87575;
        @include ps_50_50;
        top: 17%;
        @include tab {
          top: 16.5%;
        }
        @include sp {
          width: 130px;
          height: 60px;
          top: 21%;
        }
        span {
          width: 100%;
          display: block;
          position: absolute;
          @include ps_50_50;
          top: 40%;
          text-align: center;
          @include fz(20);
          font-weight: 500;
          letter-spacing: -.05em;
          color: #fff;
          @include sp {
            @include fz(13);
            line-height: 1.4em;
          }
        }
        @include tab_s {
          display: block;
        }
      }
      &_li {
        width: 100%;
        height: 186px;
        background: url(../../assets/img/ex/ex_img10_pc.png) center /contain no-repeat;
        position: relative;
        @include tab {
          width: 321px;
          height: 320px;
          background: url(../../assets/img/ex/ex_img10_sp.png) center /contain no-repeat;
        }
        @include sp {
          height: auto;
          background: url(../../assets/img/ex/ex_img10_sp.png) center /contain no-repeat;
        }
        p {
          width: 100%;
          font-family: $font_utsukushi;
          @include fz(18);
          font-weight: 400;
          @include ps_50_50;
          background: none!important;
          @include sp {
            @include fz(10);
            font-weight: 500;
          }
        }
      }
    }
  }
}
/*-------------------------------
探し方
-------------------------------*/
#search {
  background:#f2e7de;
  .sec_ttl {
    &:before {
      height: 82px;
      background: url(../../assets/img/search/search_ttl_img.png) center 50% /contain no-repeat;
      @include sp {
      height: 55px;
      }
    }
    &_s {
      color: #af8046;
    }
  }
  .search_item {
    width: 100%;
    background: #fff;
    position: relative;
    background-image: url(../../assets/img/search/t_lt.png),url(../../assets/img/search/t_rt.png), url(../../assets/img/search/b_lt.png), url(../../assets/img/search/b_rt.png),url(../../assets/img/search/m_tp.png), url(../../assets/img/search/m_bt.png), url(../../assets/img/search/m_lt.png), url(../../assets/img/search/m_rt.png);
    background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-x, repeat-x, repeat-y, repeat-y;
    background-position: left top, right top, left bottom, right bottom, top, bottom, left, right;
    background-color: #FFFFFF;
    box-sizing : border-box;
    padding: 114px;
    @include tab {
      background-image: url(../../assets/img/search/t_lt_sp.png),url(../../assets/img/search/t_rt_sp.png), url(../../assets/img/search/b_lt_sp.png), url(../../assets/img/search/b_rt_sp.png),url(../../assets/img/search/m_tp_sp.png), url(../../assets/img/search/m_bt_sp.png), url(../../assets/img/search/m_lt_sp.png), url(../../assets/img/search/m_rt_sp.png);
      padding: 70px;
    }
    @include sp {
      background-image: url(../../assets/img/search/t_lt_sp_s.png),url(../../assets/img/search/t_rt_sp_s.png), url(../../assets/img/search/b_lt_sp_s.png), url(../../assets/img/search/b_rt_sp_s.png),url(../../assets/img/search/m_tp_sp_s.png), url(../../assets/img/search/m_bt_sp_s.png), url(../../assets/img/search/m_lt_sp_s.png), url(../../assets/img/search/m_rt_sp_s.png);
      padding: 40px;
    }
    &_img {
      @include sp {
        width: 70%;
        margin: 0 auto;
      }
    }
  }
  .note {
      background-color: #fff;
      background-image: linear-gradient(180deg, #ece7d7 1px, transparent 1px);
      background-size: 100% 2.5em;
      line-height: 2.5em;
      padding-bottom: 1px;
      @include sp {
        background-size: 100% 2em;
        line-height: 2em;
      }
      .search_ttl {
      font-family: $font_utsukushi;
      @include fz(31);
      color: #af8046;
      font-weight: 500;
      line-height: inherit;
      padding-top: 2px;
      @include sp {
        @include fz(20);
        font-weight: 600;
      }
      sup {
       @include fz(15);
      }
    }
    .sec_txt {
      @include fz(18);
      line-height: inherit;
      font-weight: 400;
      text-align: left;
      margin-left: 15%;
      @include tab {
       margin-left: 0;
      }
      @include sp {
        @include fz(17);
        margin-left: 0;
        text-align: justify;
      }
    }
    .sec_cat {
      @include fz(20);
      line-height: inherit;
      font-weight: bold;
      @include sp {
        @include fz(18);
        text-align: justify;
      }
      span {
        color: #e8928b;
      }
    }
  }
}
/*--------------------------------------
 form
---------------------------------------*/
  #contact {
    background:#f2e7de;
  }
  /* placeholder Chrome */
  ::-webkit-input-placeholder {
    color: #ccc;
    @include fz(20);

    @include sp {
      font-size: .9rem;
    }
  }
  /* placeholder Firefox */
  ::-moz-placeholder {
    color: #ccc;
    @include fz(20);
  }
  /* placeholder IE */
  :-ms-input-placeholder {
    color: #ccc;
    @include fz(20);
  }
.input_s input::-webkit-input-placeholder {
    text-align: right;
}

.form_content {
  width: 100%;
  margin: 0 auto;
  @include fx_w_js;

   @include tab {
    padding: 3em 2em;
   }
   @include sp {
    padding: 3em 1em;
   }

  dt {
    width: 25%;
    @include fz(20);
    line-height: 1.4em;
    margin: 0 0 30px 0;
    text-align: left;
    @include fx_c_js;

    @include tab_s {
      width: 100%;
      margin-bottom: .5em;
    }
    @include sp {
     @include fz(16);
    }
  }
  dd {
    width: 70%;
    @include fz(20);
    line-height: 1.6em;
    margin-bottom: 30px;

    @include tab {
      width: 100%;
      margin-bottom: 2.5em;
    }
    @include sp {
      width: 100%;
      @include fz(16);
      line-height: 1.5em;
    }
  }
  .form_dd {
     @include fx_c_js;
     flex-wrap: wrap;
    .inputs {
      width: 48%;
    }
  }
  .inputs {
    width: 100%;
    height: 58px;

    @include sp {
      margin-bottom: 1em;
    }
  }
  .form_item {
    &_dt {
     width: 100%;
     justify-content: flex-start;
     margin-bottom: 15px;
      .free {
        margin-left: 1em;
      }
      &:nth-last-of-type(2) {
        padding-top: 25px;
      }
    }
    &_dd {
      width: 100%;
    }
  }
  .must, .free {
    width: 60px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    border-radius: .2em;
    @include fz(20);
    color: #fff;
    background: $clr_org;

    @include tab_s {
      margin: 0 auto 0 1em;
    }

    @include sp {
      @include fz(15);
    }
  }
  .free {
    background: #a1a1a1;
  }
}

.select_item {
    width: 45%;
    overflow: hidden;
    text-align: center;

    select {
      width: 100%;
      cursor: pointer;
      text-indent: 0.01px;
      text-overflow: ellipsis;
      border: none;
      outline: none;
      background: transparent;
      background-image: none;
      box-shadow: none;
      appearance: none;
      padding: 0 15px;
      height: 55px;
      color: $clr_base;
      @include fz(20);
      &::-ms-expand {
        display: none;
      }
      &:invalid {
        color: #ccc;
      }
    }
    &.select_lt {
      position: relative;
      border-radius: .2em;
      border: 1px solid #fff;
      background: #ffffff;

      &::before {
        position: absolute;
        top: 50%;
        right: 0.9em;
        margin-top: -4px;
        width: 0;
        height: 0;
        padding: 0;
        content: '';
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #ccc;
        pointer-events: none;
      }
    }
  }
input[type="text"], input[type="email"],
textarea {
  width: 47%;
  background: #fff;
  padding: 0.8em;
  outline: none;
  border: 1px solid #fff;
  border-radius: .2em;
  background-clip: padding-box;
  line-height: 1.8em;
  font-size: 100%;
}

input[type="text"]:focus, input[type="email"]:focus,
textarea:focus {
  box-shadow: 0 0 7px $clr_org;
  border: 1px solid $clr_org;
}
.textarea {
  width: 100%;
  height: 150px;
  max-width: 100%;
  min-width: 100%;
  max-height: 50px;
  min-height: 50px;
  padding: 1.2em .8em .8em .8em;
  box-sizing: border-box;
  @include tab {
    max-width: 85%;
    min-width: 100%;
  }
  @include sp {
    max-width: 80%;
    min-width: 100%;
    height: 80px;
    min-height: 80px;
  }
}
.com_txt {
    max-height: 250px;
    min-height: 250px;
}

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  appearance: button;
  border: none;
  outline: none;
  box-sizing: border-box;
  &::-webkit-search-decoration {
    display: none;
  }
  &::focus {
    outline: none;
  }
}

.form_btn_area {
  width: 80%;
  margin: 50px auto 0 auto;

  @include tab_s {
    width: 100%;
    margin: 0 auto 0;
  }
  @include sp {
    flex-direction: column;
  }
}
.form_btn {
  width: 47%;
  margin: 70px auto 0 auto;
  position: relative;

  @include sp {
    width: 100%;
  }

  input, a {
    display: block;
    width: 100%;
    height: 70px;
    line-height: 70px;
    text-align: center;
    border-radius: 3em;
    margin: 0 auto;
    @include fz(26);
    color: #fff;
    font-family: inherit;
    background: $clr-org;

    &:hover {
      cursor: pointer;
      background: #fff;
      color: $clr-org;
    }
    @include tab_s {
      width: 100%;
      height: 70px;
      line-height: 70px;
    }
  }
}
/*-------------------------------
お問い合わせ　確認画面　confirm
お問い合わせ 送信完了　contactus thanks
-------------------------------*/
.confirm_area {
  dd {
    @include sp {
      margin-bottom: 1.5em;
    }
  }
  .sec_txt {
    padding: 80px 0 0 0;
    margin: 0 auto;
  }
  .form_btn_area {
    width: 80%;
    @include fx_js;
    margin: 50px auto 0 auto;

    @include sp {
      width: 100%;
      flex-direction: column;
    }
  }
}
.re_btn {
  &:after {
    left: 15%;
    transform: rotate(180deg);
  }
}
.thx_area {
  .sec_txt {
    margin-bottom: 60px;
  }
  .form_btn {
    margin:  0 auto;

    &:after {
      left: 15%;
      transform: rotate(180deg);
    }
  }
}

/*-------------------------------
お問い合わせ　エラー画面　contactus error
-------------------------------*/
.error {
  display: block;
  color:red;
  font-weight: bold;
  line-height: 2em;
  font-size: 1rem!important;
  margin: 0 auto 0 0;
}

/*-------------------------------
  Footer
-------------------------------*/
.footer {
  width: 100%;
  position: relative;
  background: #fff;
  p, a, li {
    font-family: $font_noto;
    color: #000;
    text-align: left;
  }
  .sec_inner {
    padding: 70px 0 90px 0;

    @include tab {
      max-width: 80%;
    }
    @include sp {
      max-width: 75%;
      padding: 2em 0;
    }
  }
  .f_nav_top {
    margin-bottom: 50px;
    &_inner {
      display: flex;
      align-items: flex-start;
      margin-bottom: 25px;
      @include sp {
        flex-direction: column;
        margin-bottom: 1em;
      }
    }
    .f_logo {
      display: flex;
      align-items: center;
      margin-right: 25px;
      @include sp {
        margin: 0 0 .5em 0;
      }
      &_s {
        margin-left: 25px;
        @include tab_s {
          width: 50%;
        }
      }
    }
    &_txt, a {
      @include fz(12);
      font-weight: 300;
      &_s {
        font-weight: 400;
      }
    }
    .f_txt {
      @include fz(10);
      font-weight: 300;
    }
  }
  .f_nav_btm {
    position: relative;
    &:before {
      content: "";
      display: inline-block;
      background: #ccc;
      width: 100vw;
      height: 2px;
      margin: 0 calc(50% - 50vw);
      position: absolute;
      top: -25px;
      left: 0;
    }
  }

  .footer_nav {
    @include fx_w_js;
    @include sp {
      flex-direction: column;
    }
    a {
      @include fz(12);
      margin-right: 25px;
      @include tab_s {
        margin: 0 0 .5em 0;
      }
    }
  }
  .f_copy {
    display: block;
    @include fz(12);
    color: #777777;
    margin-left: auto;

    @include sp {
      text-align: center;
      margin-left: 0;
      padding-top: 1em;
    }
  }
}
/*-------------------------------
  page-top
-------------------------------*/
#page-top {
  position: fixed;
  z-index: 3;
  bottom: 1em;
  right: 1em;
  
    @include sp{
			right: .5em;
		}
    
		 a {
		  display: block;
		  background: $clr_org;
		  width: 65px;
		  height: 65px;
		  line-height: 65px;
      border-radius: 50%;
		  position: relative;
       
			@include sp{
			  width: 50px;
		  	height: 50px;
        line-height: 50px;
        @include fz(14);
        padding-top: .3em;
			}
      @include sp_s{
			  width: 40px;
		  	height: 40px;
        line-height: 40px;
			}
		&:before {
			content:  '';
			width: 15px;
			height: 15px;
			display: block;
			border-top: solid 2px #fff;
			border-right: solid 2px #fff;
			transform: rotate(-45deg);
			position:  absolute;
			top: 1.7em;
			left: 0;
			right: 0;
			margin: auto;
			transform: rotate(-45deg);
			
			@include sp{
			  top: 20px;
			}
      @include sp_s{
        top: 17px;
			  width: 10px;
			  height: 10px;
			}
		}
	}
}

