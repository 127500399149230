@charset "UTF-8";


/* ********************************************************
	共通初期化CSS
******************************************************** */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400&display=swap");
html, body, div, header, footer, article, section, aside, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, font, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, figure {
  margin: 0px;
  padding: 0px;
  border: 0px;
  outline: none;
}

* {
  margin: 0;
  padding: 0;
  outline: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

html, body {
  -webkit-overflow-scrolling: touch;
  -webkit-text-size-adjust: 100%;
  line-height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
}

ol, ul, li {
  list-style: none;
}

img {
  -webkit-backface-visibility: hidden;
  -ms-interpolation-mode: bicubic;
  vertical-align: bottom;
  max-width: 100%;
  height: auto;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

:focus {
  outline: 0;
}

caption, th, td {
  text-align: left;
  font-weight: normal;
}

.clear, div .clear {
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  text-indent: -9999px;
  clear: both;
}

/*---clearFix---*/
.clearFix {
  zoom: 1;
}

.clearFix:after {
  content: ".";
  clear: both;
  display: block;
  height: 0;
  visibility: hidden;
}

@media print {
  body {
    zoom: 60%;
  }
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
  padding: 0;
  appearance: none;
}

@font-face {
  font-family: 'Utsukushifont';
  /* フォーマットごとにパスを指定 */
  src: url("../../assets/font/utsukushifont.ttf") format("truetype");
  src: url("../../assets/font/utsukushifont.woff") format("woff");
}

/*-------------------------------
  flex
-------------------------------*/
/*-------------------------------
  font
-------------------------------*/
/*-------------------------------
  width
-------------------------------*/
/*-------------------------------
 break-point
-------------------------------*/
/*-------------------------------
 browser
-------------------------------*/
/*------------------------------
  position
-------------------------------*/
@font-face {
  font-family: 'Utsukushifont';
  /* フォーマットごとにパスを指定 */
  src: url("../../assets/font/utsukushifont.ttf") format("truetype");
  src: url("../../assets/font/utsukushifont.woff") format("woff");
}

/*-------------------------------
contents
-------------------------------*/
/*--------------------------------------
  header
---------------------------------------*/
.header_top {
  max-width: 1300px;
  height: 95px;
  margin: 0 auto 50px auto;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 1350px) {
  .header_top {
    width: 90%;
  }
}

@media screen and (max-width: 900px) {
  .header_top {
    margin: 0 auto;
  }
}

.header_top:after {
  content: "";
  display: block;
  width: 100%;
  height: 15px;
  background: url("../../assets/img/h_line.png") center 50%/contain no-repeat;
  position: absolute;
  bottom: -15px;
  left: 0;
}

@media screen and (max-width: 667px) {
  .header_top:after {
    bottom: 0;
  }
}

@media screen and (max-width: 667px) {
  .header_top .h_logo {
    width: 30%;
  }
}

@media screen and (max-width: 667px) {
  .header_top .h_link {
    width: 40%;
    margin-right: 3em;
  }
}

.kv_area {
  width: 100%;
  height: 695px;
  background: url(../../assets/img/kv_img_pc.png) center 50%/contain no-repeat;
}

@media screen and (max-width: 1350px) {
  .kv_area {
    width: 90%;
    height: auto;
    padding-top: 50%;
    margin: 0 auto;
  }
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .kv_area {
    height: 450px;
  }
}

@media screen and (max-width: 667px) {
  .kv_area {
    height: 450px;
    background: url(../../assets/img/kv_img_sp.png) center 50%/contain no-repeat;
  }
}

.header_contents {
  width: 100%;
  margin: 0 auto 90px auto;
  position: relative;
}

@media screen and (max-width: 667px) {
  .header_contents {
    margin-bottom: 2em;
  }
}

.header_inner {
  max-width: 670px;
  margin: 0 auto;
  position: relative;
}

@media screen and (max-width: 667px) {
  .header_nav_contents {
    max-width: 100%;
    height: 100vh;
    background: #e9ddcd;
    text-align: center;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 99;
    padding-top: 0;
  }
}

.header_nav_contents ul {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 667px) {
  .header_nav_contents ul {
    width: 100%;
    flex-direction: column;
    align-items: stretch;
    padding-top: 7em;
  }
  .header_nav_contents ul li {
    margin-bottom: 2em;
  }
}

.header_nav_contents ul .h_nav {
  display: block;
  font-family: "Utsukushifont", sans-serif;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 600;
  position: relative;
}

.header_nav_contents ul .h_nav:after {
  content: "";
  display: block;
  background: #eacebe;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  bottom: -50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.header_nav_contents ul .h_nav:hover {
  position: relative;
  opacity: 1;
}

.header_nav_contents ul .h_nav:hover:after {
  background: #e8928b;
}

.header_nav_contents ul .h_nav sup {
  font-size: 10px;
  font-size: 0.625rem;
}

.nav_txt {
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .nav_txt {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  .nav_txt {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

.drawer_nav_wrapper,
.menu_trigger {
  display: none;
}

@media screen and (max-width: 667px) {
  .drawer_nav_wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    justify-content: flex-end;
    padding: 0 20px;
    position: fixed;
    top: 32px;
    right: 0;
    z-index: 999;
    box-sizing: border-box;
  }
  .menu_trigger {
    display: inline-block;
    position: relative;
    width: 36px;
    height: 27px;
    vertical-align: middle;
    cursor: pointer;
  }
  .menu_trigger span {
    display: inline-block;
    box-sizing: border-box;
    position: absolute;
    left: 0;
    width: 2rem;
    height: 3px;
    background: #e8928b;
    transition: all .5s .2s;
  }
  .menu_trigger.active span {
    background: #e8928b;
  }
  .menu_trigger span:nth-of-type(1) {
    top: 0;
  }
  .menu_trigger.active span:nth-of-type(1) {
    transform: translateY(12px) rotate(-45deg);
  }
  .menu_trigger span:nth-of-type(2) {
    top: 12px;
  }
  .menu_trigger.active span:nth-of-type(2) {
    opacity: 0;
  }
  .menu_trigger span:nth-of-type(3) {
    bottom: 0;
  }
  .menu_trigger.active span:nth-of-type(3) {
    transform: translateY(-12px) rotate(45deg);
  }
  .active {
    overflow: hidden;
  }
  .active .header_nav_contents {
    visibility: visible;
    opacity: 1;
  }
  .active .header_nav_contents li {
    opacity: 1;
    transform: translateX(0);
    transition: transform 1s ease, opacity .9s ease;
    text-align: center;
  }
  .header_nav_contents li {
    opacity: 0;
    transform: translateX(200px);
    transition: transform .6s ease, opacity .2s ease;
  }
  .header_nav_contents li:nth-child(2) {
    transition-delay: .15s;
  }
  .header_nav_contents li:nth-child(3) {
    transition-delay: .3s;
  }
  .header_nav_contents li:nth-child(4) {
    transition-delay: .45s;
  }
  .header_nav_contents li:nth-child(5) {
    transition-delay: .65s;
  }
  .header_nav_contents li:nth-child(6) {
    transition-delay: .75s;
  }
}

/*-------------------------------
common 
-------------------------------*/
.sec_contents {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

.sec_inner {
  max-width: 1000px;
  margin: 0 auto;
  padding: 75px 0;
  text-align: center;
}

@media screen and (max-width: 1000px) {
  .sec_inner {
    max-width: 85%;
  }
}

@media screen and (max-width: 667px) {
  .sec_inner {
    padding: 2em 0;
  }
}

.sec_ttl {
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}

.sec_ttl:before {
  content: "";
  display: inline-block;
  background: url(../../assets/img/concept/concept_ttl_img.png) center 50%/contain no-repeat;
  width: 100%;
  height: 63px;
  margin-bottom: 30px;
}

@media screen and (max-width: 667px) {
  .sec_ttl:before {
    height: 50px;
    margin-bottom: .5em;
  }
}

.sec_ttl_item {
  font-size: 16px;
  font-size: 1rem;
  line-height: 1.5em;
  margin-bottom: 10px;
}

.sec_ttl_s {
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .sec_ttl_s {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  .sec_ttl_s {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

.sec_ttl span {
  display: block;
}

.sec_lead {
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  font-size: 31px;
  font-size: 1.9375rem;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .sec_lead {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  .sec_lead {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

.sec_txt {
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 500;
}

@media screen and (max-width: 667px) {
  .sec_txt {
    font-size: 16px;
    font-size: 1rem;
  }
}

.sec_cat_sd {
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  font-size: 37px;
  font-size: 2.3125rem;
  color: #666666;
  margin-bottom: 30px;
  position: relative;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .sec_cat_sd {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  .sec_cat_sd {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

.sec_cat_sd:before, .sec_cat_sd:after {
  content: "";
  display: inline-block;
  width: 2px;
  height: 40px;
  background: #666666;
  transform: rotate(-45deg);
  position: absolute;
  bottom: 8%;
}

@media screen and (max-width: 667px) {
  .sec_cat_sd:before, .sec_cat_sd:after {
    height: 25px;
  }
}

.sec_cat_sd:before {
  left: 40%;
}

@media screen and (max-width: 667px) {
  .sec_cat_sd:before {
    left: 30%;
  }
}

.sec_cat_sd:after {
  transform: rotate(-140deg);
  right: 40%;
}

@media screen and (max-width: 667px) {
  .sec_cat_sd:after {
    right: 30%;
  }
}

@media screen and (max-width: 667px) {
  .anchor {
    padding-top: 70px;
    margin-top: -70px;
  }
}

.btn {
  display: block;
  width: 430px;
  height: 70px;
  margin: 80px auto;
  padding-top: 3px;
  border-radius: 2em;
  font-size: 26px;
  font-size: 1.625rem;
  color: #fff;
  text-align: center;
  background: #e4935d;
  border: 2px solid #e4935d;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: relative;
}

@media screen and (max-width: 667px) {
  .btn {
    width: 90%;
    height: auto;
    margin: 2em auto;
    padding: .5em 0;
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.2em;
  }
}

.btn:after {
  content: '';
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 7%;
  margin-top: -6px;
  width: 12px;
  height: 12px;
  border-right: 2px solid #fff;
  border-bottom: 2px solid #fff;
  transform: rotate(-45deg);
}

.btn:hover {
  color: #e4935d;
  background: #fff;
}

.btn:hover:after {
  border-right: 2px solid #e4935d;
  border-bottom: 2px solid #e4935d;
}

/* fadeUp */
.fadeUp {
  animation-name: fadeUpAnime;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

@keyframes fadeUpAnime {
  from {
    opacity: 0;
    transform: translateY(100px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.fadedown {
  animation-name: fadedown;
  animation-duration: 1s;
  animation-fill-mode: forwards;
  opacity: 0;
}

/*fadedown*/
@keyframes fadedown {
  from {
    opacity: 0;
    transform: translateY(0);
  }
  to {
    opacity: 1;
    transform: translateY(50px);
  }
}

.fadeUpTrigger {
  opacity: 0;
}

#contents {
  max-width: 100%;
  margin: 0 auto;
  position: relative;
}

sup {
  font-size: 18px;
  font-size: 1.125rem;
  vertical-align: top;
  position: relative;
  top: -0.5em;
}

/*-------------------------------
戸建大家さんって？
-------------------------------*/
#concept .sec_inner {
  max-width: 1020px;
}

@media screen and (max-width: 900px) {
  #concept .sec_inner {
    width: 90%;
  }
}

@media screen and (max-width: 667px) {
  #concept .sec_inner .tab_sp {
    width: 85%;
    margin: 0 auto;
  }
}

#concept .sec_inner .sec_ttl_s sup {
  font-size: 16px;
  font-size: 1rem;
  top: -0.9em;
}

#concept .sec_inner .sec_txt sup {
  top: -0.2em;
}

#concept .sec_concept {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;
}

@media screen and (max-width: 900px) {
  #concept .sec_concept {
    flex-direction: column;
  }
}

@media screen and (max-width: 667px) {
  #concept .sec_concept {
    width: 90%;
    margin: 0 auto 3em auto;
  }
}

#concept .sec_concept_lt {
  width: 390px;
  height: 390px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: -5.5em;
  padding-top: 3em;
}

@media screen and (max-width: 900px) {
  #concept .sec_concept_lt {
    margin: 4em auto 8em;
  }
}

#concept .sec_concept_lt_cat {
  margin-bottom: 30px;
}

#concept .sec_concept_lt_mt {
  width: 188px;
  height: 89px;
  background: url(../../assets/img/concept/concept_item_img_m.png) center 50%/contain no-repeat;
  position: relative;
  z-index: 2;
}

#concept .sec_concept_lt_mt:hover {
  cursor: pointer;
  background: url(../../assets/img/concept/concept_item_img_m_on.png) center 50%/contain no-repeat;
}

#concept .sec_concept_lt_mt:before {
  content: "";
  display: inline-block;
  background: url(../../assets/img/concept/concept_item_img_t.png) center 50%/contain no-repeat;
  width: 210px;
  height: 58px;
  position: absolute;
  top: -65%;
  left: -5%;
  z-index: -1;
}

#concept .sec_concept_lt_mt:after {
  content: "";
  display: inline-block;
  background: url(../../assets/img/concept/concept_item_txt_pc.png) center 50%/contain no-repeat;
  width: 237px;
  height: 167px;
  position: absolute;
  top: -130%;
  right: -125%;
}

@media screen and (max-width: 900px) {
  #concept .sec_concept_lt_mt:after {
    width: 300px;
    height: 290px;
    background: url(../../assets/img/concept/concept_item_txt_sp.png) center 50%/contain no-repeat;
    top: -270%;
    right: -50%;
  }
}

#concept .sec_concept_lt_bt {
  width: 188px;
  height: 85px;
  background: url(../../assets/img/concept/concept_item_img_b.png) center 50%/contain no-repeat;
  position: relative;
}

#concept .sec_concept_lt_bt:hover {
  cursor: pointer;
  background: url(../../assets/img/concept/concept_item_img_b_on.png) center 50%/contain no-repeat;
}

#concept .sec_concept_lt_bt:after {
  content: "";
  display: inline-block;
  background: url(../../assets/img/concept/concept_item_txt02_pc.png) center 50%/contain no-repeat;
  width: 237px;
  height: 144px;
  position: absolute;
  top: 45%;
  right: -125%;
}

@media screen and (max-width: 900px) {
  #concept .sec_concept_lt_bt:after {
    width: 300px;
    height: 190px;
    background: url(../../assets/img/concept/concept_item_txt02_sp.png) right/contain no-repeat;
    right: -40%;
  }
}

#concept .sec_concept_rt_tp {
  margin-bottom: 55px;
  position: relative;
  z-index: 2;
}

#concept .sec_concept_rt_tp:before {
  content: "";
  display: inline-block;
  width: 483px;
  height: 335px;
  background: url(../../assets/img/concept/concept_item_rt_bg.png) center 50%/contain no-repeat;
  position: absolute;
  top: -6.5%;
  right: -5%;
  z-index: -2;
}

@media screen and (max-width: 667px) {
  #concept .sec_concept_rt_tp:before {
    width: 305px;
    height: 215px;
    top: -10%;
  }
}

#concept .sec_concept_rt_tp_inner {
  position: relative;
}

@media screen and (max-width: 667px) {
  #concept .sec_concept_rt_tp_inner {
    width: 270px;
  }
}

#concept .sec_concept_rt_tp_inner:before, #concept .sec_concept_rt_tp_inner:after {
  content: "";
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  bottom: -7px;
  z-index: 1;
}

@media screen and (max-width: 667px) {
  #concept .sec_concept_rt_tp_inner:before, #concept .sec_concept_rt_tp_inner:after {
    width: 10px;
    height: 10px;
    bottom: -3px;
  }
}

#concept .sec_concept_rt_tp_inner:before {
  left: -7px;
}

@media screen and (max-width: 667px) {
  #concept .sec_concept_rt_tp_inner:before {
    left: -3px;
  }
}

#concept .sec_concept_rt_tp_inner:after {
  right: -7px;
}

@media screen and (max-width: 667px) {
  #concept .sec_concept_rt_tp_inner:after {
    right: -3px;
  }
}

#concept .sec_concept_rt_tp_img {
  width: 440px;
  height: 248px;
  overflow: hidden;
  position: relative;
}

@media screen and (max-width: 667px) {
  #concept .sec_concept_rt_tp_img {
    width: 100%;
    height: 150px;
  }
}

#concept .sec_concept_rt_tp_img img {
  transition: 1s all;
}

#concept .sec_concept_rt_tp_img img:hover {
  cursor: pointer;
  transform: scale(1.2);
  transition: 1s all;
}

#concept .sec_concept_rt_tp:nth-of-type(2) {
  margin-bottom: 0;
}

#concept .sec_img {
  margin-bottom: 35px;
}

#concept .sec_txt_img {
  margin-bottom: 25px;
}

#concept .sec_item {
  width: 97%;
  margin: 0 auto;
  background: #fff;
  text-align: center;
}

@media screen and (max-width: 667px) {
  #concept .sec_item {
    width: 90%;
  }
}

#concept .sec_item_ttl_area {
  width: 100%;
  height: auto;
  padding-top: calc(114 / 921 * 100%);
  background: url(../../assets/img/concept/concept_ttl_img_bg.png) center 50%/contain no-repeat;
  position: relative;
  margin-bottom: 55px;
}

@media screen and (max-width: 667px) {
  #concept .sec_item_ttl_area {
    background: url(../../assets/img/concept/concept_ttl_img_bg_sp.png) center 50%/contain no-repeat;
    padding-top: 27%;
    margin-bottom: 2em;
  }
}

#concept .sec_item_ttl {
  font-size: 36px;
  font-size: 2.25rem;
  color: #fff;
  font-weight: 600;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 35%;
}

@media screen and (max-width: 900px) {
  #concept .sec_item_ttl {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

@media screen and (max-width: 667px) {
  #concept .sec_item_ttl {
    font-size: 20px;
    font-size: 1.25rem;
    line-height: 1.2em;
  }
}

#concept .sec_item_ttl_s {
  margin-bottom: 20px;
}

#concept .sec_item_js {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 667px) {
  #concept .sec_item_js {
    flex-direction: column;
  }
}

#concept .sec_item_img {
  margin-bottom: 40px;
}

#concept .sec_item .sec_txt {
  margin-bottom: 25px;
}

#concept .sec_item .sec_cat {
  margin-bottom: 10px;
}

#concept .sec_item .arrow {
  margin-top: 6em;
}

@media screen and (max-width: 667px) {
  #concept .sec_item .arrow {
    transform: rotate(90deg);
    margin-top: -2em;
  }
}

#concept .sec_cat_sd {
  margin-bottom: 20px;
}

#concept .ac_cc img {
  transform: scale(1.2);
  transition: 1s all;
}

#concept .ac_cc02 {
  background: url(../../assets/img/concept/concept_item_img_m_on.png) center 50%/contain no-repeat !important;
  transition: 1s all;
}

#concept .ac_cc03 {
  background: url(../../assets/img/concept/concept_item_img_b_on.png) center 50%/contain no-repeat !important;
  transition: 1s all;
}

/*-------------------------------
実例
-------------------------------*/
#case {
  background: #fff;
}

#case .sec_inner {
  padding-bottom: 100px;
}

#case .sec_ttl {
  margin-bottom: 90px;
}

@media screen and (max-width: 667px) {
  #case .sec_ttl {
    margin-bottom: 2em;
  }
}

#case .sec_ttl:before {
  height: 67px;
  background: url(../../assets/img/case/case_ttl_img.png) center 50%/contain no-repeat;
}

@media screen and (max-width: 667px) {
  #case .sec_ttl:before {
    height: 55px;
  }
}

#case .sec_ttl_s {
  color: #e4935d;
}

#case .case_item {
  margin-bottom: 150px;
}

@media screen and (max-width: 667px) {
  #case .case_item {
    margin-bottom: 4em;
  }
}

#case .case_item .sec_cat {
  width: 80%;
  font-size: 36px;
  font-size: 2.25rem;
  font-weight: 500;
  color: #fff;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #case .case_item .sec_cat {
    width: 85%;
    font-size: 30px;
    font-size: 1.875rem;
    line-height: 1.1em;
  }
}

@media screen and (max-width: 667px) {
  #case .case_item .sec_cat {
    width: 75%;
    font-size: 22px;
    font-size: 1.375rem;
    line-height: 1.1em;
  }
}

#case .case_item .sec_cat_area {
  max-width: 830px;
  margin: 0 auto 60px auto;
}

@media screen and (max-width: 667px) {
  #case .case_item .sec_cat_area {
    margin-bottom: 2em;
  }
}

#case .case_item .sec_cat_inner {
  width: 95%;
  height: 75px;
  line-height: 75px;
  margin: 0 0 0 auto;
  background: #c0716d;
  border-top: 1px solid #000;
  border-bottom: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 667px) {
  #case .case_item .sec_cat_inner {
    width: 85%;
  }
}

#case .case_item .sec_cat_inner:before, #case .case_item .sec_cat_inner:after {
  content: '';
  position: absolute;
  width: 0px;
  height: 0px;
  z-index: 1;
  top: 0;
  border-width: 37px 20px 37px 0;
  border-style: solid;
}

#case .case_item .sec_cat_inner:before {
  right: 1px;
  border-color: transparent #000 transparent transparent;
}

#case .case_item .sec_cat_inner:after {
  right: 0;
  border-width: 37px 20px 37px 0;
  border-color: transparent #fff transparent transparent;
}

#case .case_item .sec_cat_num {
  display: block;
  width: 125px;
  height: 125px;
  border-radius: 50%;
  border: 1px solid #000;
  background: #fff;
  margin: 0 0 0 -5em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #case .case_item .sec_cat_num {
    width: 105px;
    height: 105px;
  }
}

@media screen and (max-width: 667px) {
  #case .case_item .sec_cat_num {
    width: 80px;
    height: 80px;
  }
}

#case .case_item .sec_cat_num:after {
  content: "";
  position: absolute;
  width: 115px;
  height: 115px;
  border-radius: 50%;
  border: 1px solid #000;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #case .case_item .sec_cat_num:after {
    width: 95px;
    height: 95px;
  }
}

@media screen and (max-width: 667px) {
  #case .case_item .sec_cat_num:after {
    width: 70px;
    height: 70px;
  }
}

#case .case_item .sec_cat_num p {
  font-family: "Utsukushifont", sans-serif;
  font-size: 18px;
  font-size: 1.125rem;
  line-height: 1.6em;
}

@media screen and (max-width: 667px) {
  #case .case_item .sec_cat_num p {
    font-size: 14px;
    font-size: 0.875rem;
  }
}

#case .case_item .sec_cat_num span {
  display: block;
  font-size: 35px;
  font-size: 2.1875rem;
}

@media screen and (max-width: 667px) {
  #case .case_item .sec_cat_num span {
    font-size: 22px;
    font-size: 1.375rem;
  }
}

#case .case_item_cat {
  width: 200px;
  height: 35px;
  line-height: 35px;
  margin: 0 auto 15px;
  border-radius: 1em;
  border: 1px solid #666666;
  font-size: 16px;
  font-size: 1rem;
  background: #ffe7ab;
}

#case .case_item_txt {
  line-height: 2.5em;
  margin-bottom: 30px;
}

@media screen and (max-width: 667px) {
  #case .case_item_txt {
    line-height: 2em;
    text-align: left;
  }
}

#case .case_item_txt_s {
  font-size: 40px;
  font-size: 2.5rem;
  color: #e8928b;
  vertical-align: middle;
  padding-left: .1em;
}

@media screen and (max-width: 667px) {
  #case .case_item_txt_s {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

#case .case_item_des {
  font-family: "Utsukushifont", sans-serif;
  font-size: 28px;
  font-size: 1.75rem;
  font-weight: bold;
  letter-spacing: -.07em;
}

@media screen and (max-width: 667px) {
  #case .case_item_des {
    font-size: 25px;
    font-size: 1.5625rem;
    text-align: left;
    margin-bottom: .5em;
  }
}

#case .case_item_des_s {
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 30px;
  font-size: 1.875rem;
  color: #e8928b;
}

@media screen and (max-width: 667px) {
  #case .case_item_des_s {
    font-size: 26px;
    font-size: 1.625rem;
  }
}

#case .case_item_des_num {
  font-family: 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 48px;
  font-size: 3rem;
  color: #e8928b;
  font-weight: 500;
}

@media screen and (max-width: 667px) {
  #case .case_item_des_num {
    font-size: 30px;
    font-size: 1.875rem;
  }
}

#case .case_item_note {
  font-size: 14px;
  font-size: 0.875rem;
}

#case .case_item:nth-last-of-type(1) {
  margin-bottom: 0;
}

#case .case_item:nth-last-of-type(1) .sec_cat:before {
  background: url(../../assets/img/case/case02.png) center 50%/contain no-repeat;
}

@media screen and (max-width: 900px) {
  #case .mv_img {
    margin-bottom: 3em;
  }
}

#case .tm_img {
  margin: 0 0 50px -5px;
}

#case .tm_img .slick-slide {
  padding: 10px 5px 0;
}

#case .tm_img .slick-slide:hover {
  cursor: pointer;
  opacity: .8;
}

@media screen and (max-width: 900px) {
  #case .slick-arrow {
    position: absolute;
    top: 45%;
    bottom: 0;
    margin: auto;
  }
  #case .slick-arrow:hover {
    cursor: pointer;
    opacity: .8;
  }
  #case .prev {
    left: 5%;
    z-index: 5;
  }
  #case .prev:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(45deg);
  }
  #case .next {
    right: 5%;
  }
  #case .next:after {
    content: "";
    display: inline-block;
    width: 20px;
    height: 20px;
    border-left: 2px solid #fff;
    border-bottom: 2px solid #fff;
    transform: rotate(-135deg);
  }
}

/*-------------------------------
8つの特徴
-------------------------------*/
#merit .sec_ttl {
  margin-bottom: 60px;
}

@media screen and (max-width: 900px) {
  #merit .sec_ttl {
    margin-bottom: 30px;
  }
}

#merit .sec_ttl:before {
  height: 83px;
  background: url(../../assets/img/merit/merit_ttl_img.png) center 50%/contain no-repeat;
}

@media screen and (max-width: 900px) {
  #merit .sec_ttl:before {
    height: 55px;
  }
}

#merit .sec_ttl_s {
  color: #c6716d;
}

#merit .merit_item {
  height: 100px;
  border-radius: 3em;
  box-shadow: 0px 12px 0px -5px #e4935d;
  background: #fff;
  margin-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (max-width: 667px) {
  #merit .merit_item {
    height: 120px;
    border-radius: 4em;
    box-shadow: 0px 10px 0px -5px #e4935d;
  }
}

#merit .merit_item:before {
  content: "";
  display: inline-block;
  background: url(../../assets/img/merit/num_01.png) left/contain no-repeat;
  width: 5%;
  height: 33px;
  position: absolute;
  left: 70px;
  top: 50%;
  margin-top: -18px;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #merit .merit_item:before {
    left: 50px;
    height: 25px;
  }
}

@media screen and (max-width: 667px) {
  #merit .merit_item:before {
    top: 15%;
    left: 13%;
    width: 6%;
    margin-top: 0;
  }
}

#merit .merit_item:hover {
  cursor: pointer;
  background: #ffe7ab;
}

#merit .merit_item:nth-of-type(even) {
  box-shadow: 0px 12px 0px -5px #e18b84;
}

@media screen and (max-width: 667px) {
  #merit .merit_item:nth-of-type(even) {
    box-shadow: 0px 10px 0px -5px #e18b84;
  }
}

#merit .merit_item:nth-of-type(2):before {
  background: url(../../assets/img/merit/num_02.png) left/contain no-repeat;
}

#merit .merit_item:nth-of-type(3):before {
  background: url(../../assets/img/merit/num_03.png) left/contain no-repeat;
}

#merit .merit_item:nth-of-type(4):before {
  background: url(../../assets/img/merit/num_04.png) left/contain no-repeat;
}

#merit .merit_item:nth-of-type(5):before {
  background: url(../../assets/img/merit/num_05.png) left/contain no-repeat;
}

#merit .merit_item:nth-of-type(6):before {
  background: url(../../assets/img/merit/num_06.png) left/contain no-repeat;
}

#merit .merit_item:nth-of-type(7):before {
  background: url(../../assets/img/merit/num_07.png) left/contain no-repeat;
}

#merit .merit_item:nth-of-type(8):before {
  background: url(../../assets/img/merit/num_08.png) left/contain no-repeat;
}

#merit .merit_item_txt {
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  font-size: 1.875rem;
  color: #c6716d;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #merit .merit_item_txt {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  #merit .merit_item_txt {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #merit .merit_item_txt {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media screen and (max-width: 667px) {
  #merit .merit_item_txt {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

#merit .btn {
  margin: 80px auto 25px auto;
}

/*-------------------------------
開発ストーリー
-------------------------------*/
#story {
  background: #f2e7de;
}

#story .sec_ttl:before {
  height: 83px;
  background: url(../../assets/img/story/story_ttl_img.png) center 50%/contain no-repeat;
}

@media screen and (max-width: 667px) {
  #story .sec_ttl:before {
    height: 55px;
  }
}

#story .sec_ttl_s {
  color: #e4935d;
}

#story .sec_txt {
  margin-bottom: 30px;
}

@media screen and (max-width: 667px) {
  #story .sec_txt {
    text-align: left;
  }
}

#story .sec_lead {
  font-size: 30px;
  font-size: 1.875rem;
  color: #e4935d;
}

@media screen and (max-width: 667px) {
  #story .sec_lead {
    font-size: 23px;
    font-size: 1.4375rem;
    text-indent: -1em;
    margin-left: 1em;
  }
}

#story .sec_cat {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 70px;
}

#story .sec_cat_s {
  font-size: 18px;
  font-size: 1.125rem;
  background: #fff;
  color: #e4935d;
  font-weight: bold;
  border-radius: 1em;
  padding: 5px 30px;
  margin-right: 5px;
}

@media screen and (max-width: 667px) {
  #story .sec_cat_s {
    display: inline-block;
    border-radius: 3em;
    margin: 0 auto .8em auto;
  }
}

#story .sec_cat_b {
  font-size: 20px;
  font-size: 1.25rem;
  margin-bottom: 20px;
}

#story .story_item {
  max-width: 800px;
  margin: 0 auto 100px auto;
  background: #fff;
  padding: 55px 0 25px 0;
}

@media screen and (max-width: 667px) {
  #story .story_item {
    padding: 2em 1em;
    margin: 0 auto 2em auto;
  }
}

#story .story_item_inner {
  margin-bottom: 25px;
}

#story .story_item_ttl {
  margin-bottom: 20px;
}

@media screen and (max-width: 667px) {
  #story .story_item_ttl {
    text-align: left;
    margin-bottom: .2em;
  }
}

#story .story_item_ttl .num {
  vertical-align: text-bottom;
  margin-right: .2em;
}

@media screen and (max-width: 667px) {
  #story .story_item_ttl .num {
    text-align: left;
    margin-right: .2em;
  }
  #story .story_item_ttl .num img {
    width: 7%;
  }
}

#story .story_item_txt {
  font-size: 20px;
  font-size: 1.25rem;
}

@media screen and (max-width: 667px) {
  #story .story_item_txt {
    font-size: 16px;
    font-size: 1rem;
    text-align: left;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_txt_sp {
    text-align: center;
  }
}

#story .story_item_txt_sp sup {
  top: -.3em;
}

#story .story_item_cat_b {
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  font-size: 40px;
  font-size: 2.5rem;
  color: #e4935d;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_cat_b {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_cat_b {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

#story .story_item_ball_area {
  position: relative;
}

#story .story_item_ball_area:before {
  content: "";
  display: inline-block;
  background: url(../../assets/img/story/story_des.png) right 50%/contain no-repeat;
  width: 100%;
  height: 304px;
  position: absolute;
  top: -25%;
  right: -2%;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_ball_area:before {
    height: 250px;
    top: 0;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_ball_area:before {
    height: 180px;
    top: 7em;
  }
}

#story .story_item_ball {
  width: 550px;
  height: 100px;
  margin: 0 auto 115px auto;
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  font-size: 20px;
  font-size: 1.25rem;
  color: #666666;
  border-radius: 3em;
  box-shadow: 5px 7px 0px 0px #e4935d;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_ball {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_ball {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_ball {
    width: 75%;
    margin: 0 auto 6em 0;
    font-size: 20px;
    font-size: 1.25rem;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_ball {
    width: 100%;
    height: 80px;
    margin: 0 auto 10em 0;
    font-size: 18px;
    font-size: 1.125rem;
  }
}

#story .story_item_ball:after {
  content: "";
  display: inline-block;
  background: url(../../assets/img/story/story_des_icon.png) right 50%/contain no-repeat;
  width: 52px;
  height: 27px;
  position: absolute;
  bottom: -26%;
  right: 8%;
}

@media screen and (max-width: 667px) {
  #story .story_item_ball:after {
    bottom: -33%;
    right: 10%;
  }
}

#story .story_item_list {
  padding: 40px 0 70px 0;
  margin: 0 auto;
  background: #fff;
  box-shadow: 0 0 9px rgba(0, 0, 0, 0.3);
  position: relative;
  z-index: 1;
}

@media screen and (max-width: 900px) {
  #story .story_item_list {
    padding: 3em 1em;
  }
}

#story .story_item_list_ttl {
  position: absolute;
  top: -60px;
  left: 50px;
}

@media screen and (max-width: 667px) {
  #story .story_item_list_ttl {
    width: 40%;
    top: -2.7em;
    left: .5em;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_list_img {
    width: 90%;
    margin: 0 auto;
  }
}

#story .story_item_list_area .story_item_cat {
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  font-size: 24px;
  font-size: 1.5rem;
  color: #666666;
  margin-bottom: 70px;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_list_area .story_item_cat {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_list_area .story_item_cat {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_list_area .story_item_cat {
    font-size: 22px;
    font-size: 1.375rem;
    margin-bottom: 1.5em;
  }
}

#story .story_item_list_area .story_item_cat sup {
  font-size: 13px;
  font-size: 0.8125rem;
}

#story .story_item_list_area:nth-last-of-type(1) .story_item_ball_area:before {
  background: url(../../assets/img/story/story_des02.png) right 50%/contain no-repeat;
  top: -70%;
  right: -9%;
  height: 411px;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_list_area:nth-last-of-type(1) .story_item_ball_area:before {
    height: 350px;
    top: 105%;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_list_area:nth-last-of-type(1) .story_item_ball_area:before {
    height: 250px;
    top: 105%;
  }
}

#story .story_item_list_area:nth-last-of-type(1) .story_item_ball {
  width: 80%;
  margin: 0 auto 115px -30px;
  text-align: justify;
  padding: 0 3.5em;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_list_area:nth-last-of-type(1) .story_item_ball {
    width: 100%;
    height: 110px;
    margin: 0 auto 10em auto;
    padding: 0 2em;
  }
  #story .story_item_list_area:nth-last-of-type(1) .story_item_ball:after {
    bottom: -25%;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_list_area:nth-last-of-type(1) .story_item_ball {
    width: 100%;
    height: 200px;
    margin: 0 auto 9em auto;
    padding: 0 1.5em;
  }
  #story .story_item_list_area:nth-last-of-type(1) .story_item_ball:after {
    bottom: -13%;
    right: 15%;
  }
}

#story .story_item_list_area:nth-last-of-type(1) .story_item_cat {
  margin-bottom: 25px;
}

#story .story_item_list_btm {
  max-width: 760px;
  margin: 30px auto 0 auto;
  padding: 30px;
  background: #79bac2;
  border-radius: .5em;
}

@media screen and (max-width: 667px) {
  #story .story_item_list_btm {
    padding: 1em;
  }
}

#story .story_item_list_btm_cat {
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  font-size: 28px;
  font-size: 1.75rem;
  color: #fff;
  margin-bottom: 15px;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_list_btm_cat {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_list_btm_cat {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_list_btm_cat {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_list_btm_cat {
    font-size: 20px;
    font-size: 1.25rem;
  }
}

#story .story_item_list_btm_txt {
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  font-size: 1.875rem;
  color: #79bac2;
  font-weight: bold;
  background: #fff;
  padding: 20px 0;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_list_btm_txt {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_list_btm_txt {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #story .story_item_list_btm_txt {
    font-size: 27px;
    font-size: 1.6875rem;
  }
}

@media screen and (max-width: 667px) {
  #story .story_item_list_btm_txt {
    font-size: 22px;
    font-size: 1.375rem;
    padding: .5em 0;
  }
}

#story .arrow {
  display: block;
  margin: -80px auto 70px auto;
}

@media screen and (max-width: 667px) {
  #story .arrow {
    width: 45%;
    margin: -70px auto 70px auto;
  }
}

#story .sec_cat_sd {
  margin-bottom: 35px;
}

#story .btn {
  margin: 70px auto 0 auto;
}

/*-------------------------------
お部屋を貸さなくても
使い方は自由自在！
-------------------------------*/
#ex {
  background: #ede2e1;
}

#ex .sec_inner {
  padding: 75px 0 55px 0;
}

#ex .ex_item_ttl {
  font-family: "Utsukushifont", sans-serif;
  font-size: 44px;
  font-size: 2.75rem;
  color: #e8928b;
  line-height: 1.4em;
  letter-spacing: -.08em;
  font-weight: bold;
  text-align: center;
  font-size: 30px;
  font-size: 1.875rem;
  letter-spacing: .08em;
  margin-bottom: 30px;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #ex .ex_item_ttl {
    font-size: 35px;
    font-size: 2.1875rem;
  }
}

@media screen and (max-width: 667px) {
  #ex .ex_item_ttl {
    font-size: 25px;
    font-size: 1.5625rem;
  }
}

#ex .ex_item_ttl span {
  display: block;
  margin-bottom: 25px;
}

#ex .ex_item_cat {
  max-width: 780px;
  height: 55px;
  margin: 0 auto 50px auto;
  border-radius: 3em;
  font-size: 20px;
  font-size: 1.25rem;
  background: #fff;
  display: flex;
  justify-content: center;
  flex-direction: column;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #ex .ex_item_cat {
    line-height: 1.3em;
    height: auto;
    padding: .5em;
  }
}

@media screen and (max-width: 667px) {
  #ex .ex_item_cat {
    height: auto;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.4em;
    padding: 1em 1.5em;
    margin-bottom: 1.5em;
    text-align: justify;
  }
}

#ex .ex_item_list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#ex .ex_item_list li {
  margin-bottom: 20px;
  position: relative;
}

@media screen and (max-width: 667px) {
  #ex .ex_item_list li {
    width: 49%;
    margin-bottom: 1em;
  }
}

#ex .ex_item_list li:nth-child(3n+2) p {
  background: #e19fb2;
}

#ex .ex_item_list li:nth-child(3n) p {
  background: #e68b73;
}

#ex .ex_item_list li:hover {
  transition: 1s all;
}

#ex .ex_item_list li:hover .ex_item_list_txt {
  display: block;
  transition: 1s all;
}

@media screen and (max-width: 900px) {
  #ex .ex_item_list li:hover .ex_item_list_txt {
    display: none;
  }
}

#ex .ex_item_list_txt {
  display: none;
  width: 195px;
  height: 95px;
  border-radius: 50% / 0 0 100% 100%;
  background: #c87575;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 17%;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #ex .ex_item_list_txt {
    top: 16.5%;
  }
}

@media screen and (max-width: 667px) {
  #ex .ex_item_list_txt {
    width: 130px;
    height: 60px;
    top: 21%;
  }
}

#ex .ex_item_list_txt span {
  width: 100%;
  display: block;
  position: absolute;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  top: 40%;
  text-align: center;
  font-size: 20px;
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: -.05em;
  color: #fff;
}

@media screen and (max-width: 667px) {
  #ex .ex_item_list_txt span {
    font-size: 13px;
    font-size: 0.8125rem;
    line-height: 1.4em;
  }
}

@media screen and (max-width: 900px) {
  #ex .ex_item_list_txt {
    display: block;
  }
}

#ex .ex_item_list_li {
  width: 100%;
  height: 186px;
  background: url(../../assets/img/ex/ex_img10_pc.png) center/contain no-repeat;
  position: relative;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #ex .ex_item_list_li {
    width: 321px;
    height: 320px;
    background: url(../../assets/img/ex/ex_img10_sp.png) center/contain no-repeat;
  }
}

@media screen and (max-width: 667px) {
  #ex .ex_item_list_li {
    height: auto;
    background: url(../../assets/img/ex/ex_img10_sp.png) center/contain no-repeat;
  }
}

#ex .ex_item_list_li p {
  width: 100%;
  font-family: "Utsukushifont", sans-serif;
  font-size: 18px;
  font-size: 1.125rem;
  font-weight: 400;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  background: none !important;
}

@media screen and (max-width: 667px) {
  #ex .ex_item_list_li p {
    font-size: 10px;
    font-size: 0.625rem;
    font-weight: 500;
  }
}

/*-------------------------------
探し方
-------------------------------*/
#search {
  background: #f2e7de;
}

#search .sec_ttl:before {
  height: 82px;
  background: url(../../assets/img/search/search_ttl_img.png) center 50%/contain no-repeat;
}

@media screen and (max-width: 667px) {
  #search .sec_ttl:before {
    height: 55px;
  }
}

#search .sec_ttl_s {
  color: #af8046;
}

#search .search_item {
  width: 100%;
  background: #fff;
  position: relative;
  background-image: url(../../assets/img/search/t_lt.png), url(../../assets/img/search/t_rt.png), url(../../assets/img/search/b_lt.png), url(../../assets/img/search/b_rt.png), url(../../assets/img/search/m_tp.png), url(../../assets/img/search/m_bt.png), url(../../assets/img/search/m_lt.png), url(../../assets/img/search/m_rt.png);
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, repeat-x, repeat-x, repeat-y, repeat-y;
  background-position: left top, right top, left bottom, right bottom, top, bottom, left, right;
  background-color: #FFFFFF;
  box-sizing: border-box;
  padding: 114px;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #search .search_item {
    background-image: url(../../assets/img/search/t_lt_sp.png), url(../../assets/img/search/t_rt_sp.png), url(../../assets/img/search/b_lt_sp.png), url(../../assets/img/search/b_rt_sp.png), url(../../assets/img/search/m_tp_sp.png), url(../../assets/img/search/m_bt_sp.png), url(../../assets/img/search/m_lt_sp.png), url(../../assets/img/search/m_rt_sp.png);
    padding: 70px;
  }
}

@media screen and (max-width: 667px) {
  #search .search_item {
    background-image: url(../../assets/img/search/t_lt_sp_s.png), url(../../assets/img/search/t_rt_sp_s.png), url(../../assets/img/search/b_lt_sp_s.png), url(../../assets/img/search/b_rt_sp_s.png), url(../../assets/img/search/m_tp_sp_s.png), url(../../assets/img/search/m_bt_sp_s.png), url(../../assets/img/search/m_lt_sp_s.png), url(../../assets/img/search/m_rt_sp_s.png);
    padding: 40px;
  }
}

@media screen and (max-width: 667px) {
  #search .search_item_img {
    width: 70%;
    margin: 0 auto;
  }
}

#search .note {
  background-color: #fff;
  background-image: linear-gradient(180deg, #ece7d7 1px, transparent 1px);
  background-size: 100% 2.5em;
  line-height: 2.5em;
  padding-bottom: 1px;
}

@media screen and (max-width: 667px) {
  #search .note {
    background-size: 100% 2em;
    line-height: 2em;
  }
}

#search .note .search_ttl {
  font-family: "Utsukushifont", sans-serif;
  font-size: 31px;
  font-size: 1.9375rem;
  color: #af8046;
  font-weight: 500;
  line-height: inherit;
  padding-top: 2px;
}

@media screen and (max-width: 667px) {
  #search .note .search_ttl {
    font-size: 20px;
    font-size: 1.25rem;
    font-weight: 600;
  }
}

#search .note .search_ttl sup {
  font-size: 15px;
  font-size: 0.9375rem;
}

#search .note .sec_txt {
  font-size: 18px;
  font-size: 1.125rem;
  line-height: inherit;
  font-weight: 400;
  text-align: left;
  margin-left: 15%;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  #search .note .sec_txt {
    margin-left: 0;
  }
}

@media screen and (max-width: 667px) {
  #search .note .sec_txt {
    font-size: 17px;
    font-size: 1.0625rem;
    margin-left: 0;
    text-align: justify;
  }
}

#search .note .sec_cat {
  font-size: 20px;
  font-size: 1.25rem;
  line-height: inherit;
  font-weight: bold;
}

@media screen and (max-width: 667px) {
  #search .note .sec_cat {
    font-size: 18px;
    font-size: 1.125rem;
    text-align: justify;
  }
}

#search .note .sec_cat span {
  color: #e8928b;
}

/*--------------------------------------
 form
---------------------------------------*/
#contact {
  background: #f2e7de;
}

/* placeholder Chrome */
::-webkit-input-placeholder {
  color: #ccc;
  font-size: 20px;
  font-size: 1.25rem;
}

@media screen and (max-width: 667px) {
  ::-webkit-input-placeholder {
    font-size: .9rem;
  }
}

/* placeholder Firefox */
::-moz-placeholder {
  color: #ccc;
  font-size: 20px;
  font-size: 1.25rem;
}

/* placeholder IE */
:-ms-input-placeholder {
  color: #ccc;
  font-size: 20px;
  font-size: 1.25rem;
}

.input_s input::-webkit-input-placeholder {
  text-align: right;
}

.form_content {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .form_content {
    padding: 3em 2em;
  }
}

@media screen and (max-width: 667px) {
  .form_content {
    padding: 3em 1em;
  }
}

.form_content dt {
  width: 25%;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.4em;
  margin: 0 0 30px 0;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 900px) {
  .form_content dt {
    width: 100%;
    margin-bottom: .5em;
  }
}

@media screen and (max-width: 667px) {
  .form_content dt {
    font-size: 16px;
    font-size: 1rem;
  }
}

.form_content dd {
  width: 70%;
  font-size: 20px;
  font-size: 1.25rem;
  line-height: 1.6em;
  margin-bottom: 30px;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .form_content dd {
    width: 100%;
    margin-bottom: 2.5em;
  }
}

@media screen and (max-width: 667px) {
  .form_content dd {
    width: 100%;
    font-size: 16px;
    font-size: 1rem;
    line-height: 1.5em;
  }
}

.form_content .form_dd {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.form_content .form_dd .inputs {
  width: 48%;
}

.form_content .inputs {
  width: 100%;
  height: 58px;
}

@media screen and (max-width: 667px) {
  .form_content .inputs {
    margin-bottom: 1em;
  }
}

.form_content .form_item_dt {
  width: 100%;
  justify-content: flex-start;
  margin-bottom: 15px;
}

.form_content .form_item_dt .free {
  margin-left: 1em;
}

.form_content .form_item_dt:nth-last-of-type(2) {
  padding-top: 25px;
}

.form_content .form_item_dd {
  width: 100%;
}

.form_content .must, .form_content .free {
  width: 60px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: .2em;
  font-size: 20px;
  font-size: 1.25rem;
  color: #fff;
  background: #e4935d;
}

@media screen and (max-width: 900px) {
  .form_content .must, .form_content .free {
    margin: 0 auto 0 1em;
  }
}

@media screen and (max-width: 667px) {
  .form_content .must, .form_content .free {
    font-size: 15px;
    font-size: 0.9375rem;
  }
}

.form_content .free {
  background: #a1a1a1;
}

.select_item {
  width: 45%;
  overflow: hidden;
  text-align: center;
}

.select_item select {
  width: 100%;
  cursor: pointer;
  text-indent: 0.01px;
  text-overflow: ellipsis;
  border: none;
  outline: none;
  background: transparent;
  background-image: none;
  box-shadow: none;
  appearance: none;
  padding: 0 15px;
  height: 55px;
  color: #666666;
  font-size: 20px;
  font-size: 1.25rem;
}

.select_item select::-ms-expand {
  display: none;
}

.select_item select:invalid {
  color: #ccc;
}

.select_item.select_lt {
  position: relative;
  border-radius: .2em;
  border: 1px solid #fff;
  background: #ffffff;
}

.select_item.select_lt::before {
  position: absolute;
  top: 50%;
  right: 0.9em;
  margin-top: -4px;
  width: 0;
  height: 0;
  padding: 0;
  content: '';
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-top: 6px solid #ccc;
  pointer-events: none;
}

input[type="text"], input[type="email"],
textarea {
  width: 47%;
  background: #fff;
  padding: 0.8em;
  outline: none;
  border: 1px solid #fff;
  border-radius: .2em;
  background-clip: padding-box;
  line-height: 1.8em;
  font-size: 100%;
}

input[type="text"]:focus, input[type="email"]:focus,
textarea:focus {
  box-shadow: 0 0 7px #e4935d;
  border: 1px solid #e4935d;
}

.textarea {
  width: 100%;
  height: 150px;
  max-width: 100%;
  min-width: 100%;
  max-height: 50px;
  min-height: 50px;
  padding: 1.2em .8em .8em .8em;
  box-sizing: border-box;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .textarea {
    max-width: 85%;
    min-width: 100%;
  }
}

@media screen and (max-width: 667px) {
  .textarea {
    max-width: 80%;
    min-width: 100%;
    height: 80px;
    min-height: 80px;
  }
}

.com_txt {
  max-height: 250px;
  min-height: 250px;
}

input[type="submit"],
input[type="button"] {
  border-radius: 0;
  appearance: button;
  border: none;
  outline: none;
  box-sizing: border-box;
}

input[type="submit"]::-webkit-search-decoration,
input[type="button"]::-webkit-search-decoration {
  display: none;
}

input[type="submit"]::focus,
input[type="button"]::focus {
  outline: none;
}

.form_btn_area {
  width: 80%;
  margin: 50px auto 0 auto;
}

@media screen and (max-width: 900px) {
  .form_btn_area {
    width: 100%;
    margin: 0 auto 0;
  }
}

@media screen and (max-width: 667px) {
  .form_btn_area {
    flex-direction: column;
  }
}

.form_btn {
  width: 47%;
  margin: 70px auto 0 auto;
  position: relative;
}

@media screen and (max-width: 667px) {
  .form_btn {
    width: 100%;
  }
}

.form_btn input, .form_btn a {
  display: block;
  width: 100%;
  height: 70px;
  line-height: 70px;
  text-align: center;
  border-radius: 3em;
  margin: 0 auto;
  font-size: 26px;
  font-size: 1.625rem;
  color: #fff;
  font-family: inherit;
  background: #e4935d;
}

.form_btn input:hover, .form_btn a:hover {
  cursor: pointer;
  background: #fff;
  color: #e4935d;
}

@media screen and (max-width: 900px) {
  .form_btn input, .form_btn a {
    width: 100%;
    height: 70px;
    line-height: 70px;
  }
}

/*-------------------------------
お問い合わせ　確認画面　confirm
お問い合わせ 送信完了　contactus thanks
-------------------------------*/
@media screen and (max-width: 667px) {
  .confirm_area dd {
    margin-bottom: 1.5em;
  }
}

.confirm_area .sec_txt {
  padding: 80px 0 0 0;
  margin: 0 auto;
}

.confirm_area .form_btn_area {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin: 50px auto 0 auto;
}

@media screen and (max-width: 667px) {
  .confirm_area .form_btn_area {
    width: 100%;
    flex-direction: column;
  }
}

.re_btn:after {
  left: 15%;
  transform: rotate(180deg);
}

.thx_area .sec_txt {
  margin-bottom: 60px;
}

.thx_area .form_btn {
  margin: 0 auto;
}

.thx_area .form_btn:after {
  left: 15%;
  transform: rotate(180deg);
}

/*-------------------------------
お問い合わせ　エラー画面　contactus error
-------------------------------*/
.error {
  display: block;
  color: red;
  font-weight: bold;
  line-height: 2em;
  font-size: 1rem !important;
  margin: 0 auto 0 0;
}

/*-------------------------------
  Footer
-------------------------------*/
.footer {
  width: 100%;
  position: relative;
  background: #fff;
}

.footer p, .footer a, .footer li {
  font-family: "Noto Sans JP", sans-serif;
  color: #000;
  text-align: left;
}

.footer .sec_inner {
  padding: 70px 0 90px 0;
}

@media screen and (min-width: 667px) and (max-width: 900px) {
  .footer .sec_inner {
    max-width: 80%;
  }
}

@media screen and (max-width: 667px) {
  .footer .sec_inner {
    max-width: 75%;
    padding: 2em 0;
  }
}

.footer .f_nav_top {
  margin-bottom: 50px;
}

.footer .f_nav_top_inner {
  display: flex;
  align-items: flex-start;
  margin-bottom: 25px;
}

@media screen and (max-width: 667px) {
  .footer .f_nav_top_inner {
    flex-direction: column;
    margin-bottom: 1em;
  }
}

.footer .f_nav_top .f_logo {
  display: flex;
  align-items: center;
  margin-right: 25px;
}

@media screen and (max-width: 667px) {
  .footer .f_nav_top .f_logo {
    margin: 0 0 .5em 0;
  }
}

.footer .f_nav_top .f_logo_s {
  margin-left: 25px;
}

@media screen and (max-width: 900px) {
  .footer .f_nav_top .f_logo_s {
    width: 50%;
  }
}

.footer .f_nav_top_txt, .footer .f_nav_top a {
  font-size: 12px;
  font-size: 0.75rem;
  font-weight: 300;
}

.footer .f_nav_top_txt_s, .footer .f_nav_top a_s {
  font-weight: 400;
}

.footer .f_nav_top .f_txt {
  font-size: 10px;
  font-size: 0.625rem;
  font-weight: 300;
}

.footer .f_nav_btm {
  position: relative;
}

.footer .f_nav_btm:before {
  content: "";
  display: inline-block;
  background: #ccc;
  width: 100vw;
  height: 2px;
  margin: 0 calc(50% - 50vw);
  position: absolute;
  top: -25px;
  left: 0;
}

.footer .footer_nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

@media screen and (max-width: 667px) {
  .footer .footer_nav {
    flex-direction: column;
  }
}

.footer .footer_nav a {
  font-size: 12px;
  font-size: 0.75rem;
  margin-right: 25px;
}

@media screen and (max-width: 900px) {
  .footer .footer_nav a {
    margin: 0 0 .5em 0;
  }
}

.footer .f_copy {
  display: block;
  font-size: 12px;
  font-size: 0.75rem;
  color: #777777;
  margin-left: auto;
}

@media screen and (max-width: 667px) {
  .footer .f_copy {
    text-align: center;
    margin-left: 0;
    padding-top: 1em;
  }
}

/*-------------------------------
  page-top
-------------------------------*/
#page-top {
  position: fixed;
  z-index: 3;
  bottom: 1em;
  right: 1em;
}

@media screen and (max-width: 667px) {
  #page-top {
    right: .5em;
  }
}

#page-top a {
  display: block;
  background: #e4935d;
  width: 65px;
  height: 65px;
  line-height: 65px;
  border-radius: 50%;
  position: relative;
}

@media screen and (max-width: 667px) {
  #page-top a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-size: 0.875rem;
    padding-top: .3em;
  }
}

@media screen and (max-width: 320px) {
  #page-top a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

#page-top a:before {
  content: '';
  width: 15px;
  height: 15px;
  display: block;
  border-top: solid 2px #fff;
  border-right: solid 2px #fff;
  transform: rotate(-45deg);
  position: absolute;
  top: 1.7em;
  left: 0;
  right: 0;
  margin: auto;
  transform: rotate(-45deg);
}

@media screen and (max-width: 667px) {
  #page-top a:before {
    top: 20px;
  }
}

@media screen and (max-width: 320px) {
  #page-top a:before {
    top: 17px;
    width: 10px;
    height: 10px;
  }
}
