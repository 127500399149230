//カラー
$clr_base: #666666;
$clr_pnk: #e8928b;
$clr_bpnk: #e18b84;
$clr_kpnk: #c6716d;
$clr_spnk: #ede2e1;
$clr_org: #e4935d;

//フォント指定
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@300;400&display=swap');
@font-face {
  font-family: 'Utsukushifont';
  /* フォーマットごとにパスを指定 */
  src: url('../../assets/font/utsukushifont.ttf') format('truetype');
  src: url('../../assets/font/utsukushifont.woff') format('woff');
}
$font_noto: 'Noto Sans JP', sans-serif;
$font_utsukushi: 'Utsukushifont', sans-serif;
$font_yumin: 游明朝,"Yu Mincho",YuMincho,"Hiragino Mincho ProN",HGS明朝E,メイリオ,Meiryo,serif;
$font_min: "ヒラギノ明朝 Pro W6","Hiragino Mincho Pro","ＭＳ Ｐ明朝","MS PMincho",serif;
$font_min: "ヒラギノ明朝 Pro W6","Hiragino Mincho Pro","ＭＳ Ｐ明朝","MS PMincho",serif;
$font_yugo: "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", "メイリオ", sans-serif;


